<template>
	<div v-show="isVisible" class="main form-section-more-space">

		<div class="form-section top">
			<div class="form-section-label">
				Persoonlijk
			</div>
			<div class="form-section-box">
				<div>
					<div class="form-grid-2 mb-2">
						<div class="mt-2">
							<b-form-group>
								<b-form-radio-group v-model="order.person.gender" :options="genderOptions"></b-form-radio-group>
							</b-form-group>
						</div>
						<div>
						</div>
					</div>
				</div>
				<div class="form-grid-2">
					<div class="form-grid-2">
						<b-form-group>
							<b-form-input v-model="order.person.initials" placeholder="Voorletters" :formatter="formatInitials" lazy-formatter :state="initialsState" />
						</b-form-group>
						<b-form-group>
							<b-form-input v-model="order.person.middleName" placeholder="Tussenv." :state="middleNameState" :formatter="allLowercase" />
						</b-form-group>
					</div>
					<b-form-group>
						<b-form-input v-model="order.person.lastName" placeholder="Achternaam" :state="lastNameState" :formatter="firstCharUppercase" />
					</b-form-group>
				</div>
				<div class="form-line-4-cols">
					<label class="pt-2">Geboortedatum</label>
					<b-form-select name="birthdate_day" v-model="order.person.dateOfBirth.day" :options="dayOptions" :state="dayState"></b-form-select>
					<b-form-select name="birthdate_month" v-model="order.person.dateOfBirth.month" :options="monthOptions" :state="monthState"></b-form-select>
					<b-form-input placeholder="Jaar" v-model="order.person.dateOfBirth.year" :formatter="formatYear" :state="yearState" type="number" :max="maxYear" />
				</div>
			</div>
		</div>
		<div class="form-section">
			<div class="form-section-label">
				Adres
			</div>
			<div class="form-section-box">
				<div class="form-grid-2 mb-2">
					<div class="form-grid-2">
						<b-form-input placeholder="Postcode" v-model="order.address.postalCode" :state="postalCodeState" :formatter="formatPostalCode" />
						<b-form-input placeholder="Huisnr" v-model="order.address.houseNumber" :state="houseNumberState" />
					</div>
					<div class="form-grid-3">
						<b-form-input placeholder="Toev." v-model="order.address.houseNumberSuffix" :state="houseNumberSuffixState" />
						<b-form-input placeholder="Kamer" v-model="order.address.roomNumber" :state="roomNumberState" />
						<b-button :disabled="canCompleteAddress === false" @click="completeAddress">Zoek</b-button>
					</div>
					<div class="form-error" ref="address_error" v-show="addressLookupError"></div>
				</div>
				<div>

				</div>
				<div class="form-grid-2">
					<b-form-input placeholder="Straat" v-model="order.address.street" :state="streetState" :formatter="firstCharUppercase" />
					<b-form-input placeholder="Plaats" v-model="order.address.city" :state="cityState" :formatter="firstCharUppercase" />
				</div>
			</div>
		</div>
		<div class="form-section">
			<div class="form-section-label">
				Contact
			</div>
			<div class="form-section-box">
				<div class="form-grid-2 mb-2">
					<b-form-input placeholder="Telefoon" v-model="order.person.phone" :state="phoneState" :formatter="formatPhoneNumber" />
					<b-form-input placeholder="Mobiel" v-model="order.person.mobile" :state="mobileState" :formatter="formatPhoneNumber" />
				</div>
				<div class="mb-2">
					<b-form-input placeholder="Emailadres" v-model="order.person.email" type="email" :state="emailState" />
				</div>
			</div>
		</div>
		<div class="form-section">
			<div class="form-section-label">
				Levering
			</div>
			<div class="form-section-box">
				<div class="form-grid-2 mb-2">
					<b-form-radio value="asap" v-model="order.product.delivery" checked="true" @change="cleanWishDate">Zo spoedig mogelijk</b-form-radio>
					<div>
						<b-form-radio v-model="order.product.delivery" value="wishdate" class="mb-2">Wensdatum:</b-form-radio>
						<b-form-datepicker
							v-show="order.product.delivery === 'wishdate'"
							:min="minWishDate"
							id="example-datepicker"
							v-model="order.product.wishDate"
							placeholder="Kies een datum"
							locale="nl-NL"
							start-weekday="1"
							:state="wishDateState"
						></b-form-datepicker>
					</div>
				</div>
			</div>
		</div>

		<div class="mt-3" style="display: flex; justify-content: flex-end;">
			<b-button :disabled="formState === false" @click="$emit('customer-data-done')" class="btn-green">Verder</b-button>
		</div>

	</div>
</template>

<script>
import moment from 'moment'

export default {
	created() {

	},
	name: 'CustomerData',
	props: ['isVisible', 'order'],
	emits: ['customer-form-state'],
	data() {
		let minDate = moment().add(14, 'days').startOf('day');
		let minWishDate = minDate.toDate();
		return {
			addressLookupError: false,
			'minWishDate': minWishDate,
			'genderOptions': [
					{text: 'Dhr', value: 'M'},
					{text: 'Mevr', value: 'F'},
					{text: 'Dhr / Mevr', value: '-'}
			],
			'dayOptions': [{text: '-- dag --', value: null, disabled: true}, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
			'monthOptions': [
				{text: '-- maand --', value: null, disabled: true},
				{text: 'januari', value: 1},
				{text: 'februari', value: 2},
				{text: 'maart', value: 3},
				{text: 'april', value: 4},
				{text: 'mei', value: 5},
				{text: 'juni', value: 6},
				{text: 'juli', value: 7},
				{text: 'augustus', value: 8},
				{text: 'september', value: 9},
				{text: 'oktober', value: 10},
				{text: 'november', value: 11},
				{text: 'december', value: 12},
			],
		}
	},
	computed: {
		maxYear() {
			return moment().format('YYYY');
		},
		titleState()
		{
			let ret = null;
			if (this.order.person.gender === '-' && this.order.person.title)
			{
				ret = /^[A-Z .]{3,}$/i.test(this.order.person.title);
			}
			return ret;
		},
		initialsState()
		{
			let ret = null;
			if (this.order.person.initials)
			{
				ret = /^[A-Z.]+$/.test(this.order.person.initials);
			}
			return ret;
		},
		middleNameState()
		{
			let ret = null;
			if (this.order.person.middleName)
			{
				ret = /^[A-Z\-' ]+$/i.test(this.order.person.middleName);
			}
			return ret;
		},
		lastNameState()
		{
			let ret = null;
			if (this.order.person.lastName)
			{
				ret = /^[A-Z\-' ]+$/i.test(this.order.person.lastName);
			}
			return ret;
		},
		yearState()
		{
			let ret = null;
			if (this.order.person.dateOfBirth.year)
			{
				ret = /^\d{4}$/i.test(this.order.person.dateOfBirth.year);
			}
			if (ret)
			{
				let year = parseInt(this.order.person.dateOfBirth.year);
				let maxYear = moment().format('YYYY') - 18;
				if (year < 1900 || year > maxYear)
				{
					ret = false;
				}
			}

			return ret;
		},
		dayState()
		{
			// Check if day makes sense for given month
			let ret = null;

			if (this.order.person.dateOfBirth.day)
			{
				ret = true;
			}

			let maxDaysInFeb = 28;
			if (this.order.person.dateOfBirth.year)
			{
				let isLeapYear = (this.order.person.dateOfBirth.year % 4 === 0);
				if (isLeapYear)
				{
					maxDaysInFeb = 29;
				}
			}

			if (this.order.person.dateOfBirth.day && this.order.person.dateOfBirth.month)
			{
				if (this.order.person.dateOfBirth.month === 2 && this.order.person.dateOfBirth.day > maxDaysInFeb)
				{
					ret = false;
				}
				if ([4, 6, 9, 11].includes(this.order.person.dateOfBirth.month) && this.order.person.dateOfBirth.day > 30)
				{
					ret = false;
				}

			}
			return ret;
		},
		monthState()
		{
			let ret = null;
			if (this.order.person.dateOfBirth.month)
			{
				ret = true;
			}
			return ret;
		},
		streetState()
		{
			let ret = null;
			if (this.order.address.street)
			{
				ret = /^.+$/i.test(this.order.address.street);
			}
			return ret;
		},
		houseNumberState()
		{
			let ret = null;
			if (this.order.address.houseNumber)
			{
				ret = /^[A-Z0-9 -]+$/i.test(this.order.address.houseNumber);
			}
			return ret;
		},
		houseNumberSuffixState()
		{
			let ret = null;
			if (this.order.address.houseNumberSuffix)
			{
				ret = /^[A-Z0-9 -]+$/i.test(this.order.address.houseNumberSuffix);
			}
			return ret;
		},
		roomNumberState()
		{
			let ret = null;
			if (this.order.address.roomNumber)
			{
				ret = /^[A-Z0-9 ]+$/i.test(this.order.address.roomNumber);
			}
			return ret;
		},
		postalCodeState()
		{
			let ret = null;
			if (this.order.address.postalCode)
			{
				ret = /^\d{4}[A-Z]{2}$/i.test(this.order.address.postalCode);
			}
			return ret;
		},
		cityState()
		{
			let ret = null;
			if (this.order.address.city)
			{
				ret = /^[A-Z0-9 \-']+$/i.test(this.order.address.city);
			}
			return ret;
		},
		phoneState()
		{
			let ret = null;
			if (this.order.person.phone)
			{
				ret = /^\d{10}$/i.test(this.order.person.phone);
			}
			return ret;
		},
		mobileState()
		{
			let ret = null;
			if (this.order.person.mobile)
			{
				ret = /^\d{10}$/i.test(this.order.person.mobile);
			}
			return ret;
		},
		emailState()
		{
			let ret = null;
			if (this.order.person.email)
			{
				let regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
				ret = regex.test(this.order.person.email);
			}
			return ret;
		},
		wishDateState()
		{
			let ret = null;
			if (this.order.product.delivery === 'wishdate')
			{
				ret = (this.order.product.wishDate !== null);
			}
			return ret;
		},
		canCompleteAddress()
		{
			let ret = false;
			if (this.postalCodeState && this.houseNumberState)
			{
				ret = true;
			}
			return ret;
		},

		formState()
		{
			let ret = true;

			// Check gender/title
			if (this.order.person.gender === null && this.order.person.title === null)
			{
				ret = false;
			}
			// Check title, if gender is -
			if (this.order.person.gender === '-' && !this.order.person.title && this.titleState)
			{
				ret = false;
			}

			// Check name fiekds
			if (!this.initialsState || !this.lastNameState || this.middleNameState === false)
			{
				ret = false;
			}

			// Check birth date fields
			if (!this.dayState || !this.monthState || !this.yearState)
			{
				ret = false;
			}

			// Check address fields
			if (!this.streetState || !this.houseNumberState || this.houseNumberSuffixState === false || this.roomNumberState === false ||
				!this.postalCodeState || !this.cityState)
			{
				ret = false;
			}

			// Check contact fields
			if (!this.phoneState || this.mobileState === false || !this.emailState)
			{
				ret = false;
			}

			// Check delivery
			if (!this.order.product.delivery || (this.order.product.delivery === 'wishdate' && !this.wishDateState))
			{
				ret = false;
			}

			this.$emit('customer-form-state', ret);

			return ret;
		},
	},
	methods: {
		formatInitials(val)
		{
			let cleanValue = val.replace(/[^A-Z]/gi, '');
			cleanValue = cleanValue.toUpperCase();
			let newValue = '';
			for (let c of cleanValue)
			{
				newValue = newValue + c + '.';
			}
			return newValue;
		},
		formatYear(val)
		{
			let cleanValue = val.replace(/[^0-9]/gi, '');
			return cleanValue;
		},
		formatPhoneNumber(val)
		{
			let cleanValue = val.replace(/\D/g, '');
			cleanValue = cleanValue.substring(0, 10);
			return cleanValue;
		},
		formatPostalCode(val)
		{
			let cleanValue = val.replace(/[^A-Z0-9]/ig, '');
			cleanValue = cleanValue.toUpperCase();
			return cleanValue;
		},
		firstCharUppercase(val)
		{
			return val.charAt(0).toUpperCase() + val.slice(1);
		},
		allLowercase(val)
		{
			return val.toLowerCase();
		},
		cleanWishDate()
		{
			if (this.order.product.delivery === 'asap')
			{
				this.order.product.wishDate = null;
			}
		},
		completeAddress()
		{
			this.addressLookupError = false;

			let postalCode = this.order.address.postalCode;
			let houseNr = this.order.address.houseNumber;

			// Housenumber can only be numeric
			houseNr = houseNr.replace(/\D/g, '');

			// postalCode = '6545CA';
			// houseNr = '29';

			let target = 'api.aika.test.fiber.nl';
			if (location.hostname === 'd2d.fiber.nl')
			{
				target = 'api.aika.fiber.nl';
			}

			let url = 'https://' + target + '/api/address/complete';

			let data = {
				postalCode: postalCode,
				houseNumber: houseNr,
			}

			this.$http.post(url, data)
			.then(resp =>
			{
				if (resp.data.result && resp.data.result === 'OK')
				{
					this.order.address.street = resp.data.street;
					this.order.address.city = resp.data.city;
				}
				else
				{
					this.$refs.address_error.innerText = 'Probleem bij opzoeken adres';
					this.addressLookupError = true;
				}
			})
			.catch (error =>
			{
				if (error.response.status === 404)
				{
					this.$refs.address_error.innerText = 'Geen adres gevonden';
					this.addressLookupError = true;
				}
				else if (error.response.status === 422)
				{
					this.$refs.address_error.innerText = 'Ongeldige postcode of huisnr';
					this.addressLookupError = true;
				}
				else
				{
					this.$refs.address_error.innerText = 'Probleem bij opzoeken adres';
					this.addressLookupError = true;
				}
			});
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

$fiberBlue: #74d1f6;
$fiberGreen: #91c750;
$fiberOrange: #ef7a30;

.form-line-4-cols {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 1em
}

.form-grid-1-1-2 {
	display: grid;
	grid-template-columns: 1fr 1fr 2fr;
	grid-column-gap: 1em;
}

.form-line-street {
	display: grid;
	grid-template-columns: 3fr 1fr 1fr 1fr;
	grid-column-gap: 1em
}

.form-section-more-space .form-section-box {
	padding: 1em 0em 1em 1em;
}

</style>
