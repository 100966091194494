<template>
	<div v-show="isVisible" class="main">

		<div class="overlay" v-if="showInfoBox" @click="showInfoBox = false;">
			<div class="info-box" v-if="showInfoBox">
				<div v-if="productMode === 'helmond-try-buy'">
					<div class="info-box-top">
						<div>
							<img src="/images/logo-glasvezel-helmond.png" alt="Glasvezel Helmond" />
						</div>
						<div>
							<p style="color: #999;">PROFITEER VAN DE ACTIE <strong style="color: #EF7A30;">T/M 31 JANUARI 2022</strong></p>
							<h1>Probeer nu <strong>3 maanden gratis en vrijblijvend </strong> glasvezelinternet!</h1>
						</div>
					</div>
					<ul>
						<li>Alles wordt gratis geïnstalleerd bij u thuis door onze monteur. </li>
						<li>U betaalt geen eenmalige activatie kosten (alleen borg voor de apparatuur). </li>
						<li>U kunt binnen 3 maanden eenvoudig opzeggen door email te sturen of ons te bellen.</li>
					</ul>
					<div class="center">
						<button class="plan-order" @click="showInfoBox = false;">Bestellen</button>
					</div>
				</div>
			</div>
		</div>

		<div class="plan-card">
			<div class="plan-name">
				<h2>Internet Only</h2>
				<h3 class="font-light">(+ bellen)</h3>
			</div>
			<div class="plan-details">
				<ul>
					<li>Supersnel internet tot 1.000 Mbps up / down</li>
					<li>Gratis wifi router (Fritz!Box)</li>
					<li>
						Optioneel: GRATIS vaste telefoonlijn met gratis nummerbehoud <br/>
						Voordelig bellen 10ct / min
					</li>
					<li>Incl. gratis basis installatie</li>
				</ul>
			</div>
			<div class="plan-sidebox">
				<div class="plan-price">
					<div v-if="productMode === 'helmond-try-buy'" @click="showInfoBox = true">
						<div class="price">1e 3 mnd gratis</div>
						<div class="price-detail">daarna 44,95 p/mnd</div>
					</div>
					<div v-if="productMode === ''">
						<div class="price">44,95</div>
						<div class="price-detail">p/mnd</div>
					</div>
				</div>
				<button class="plan-order" @click="$emit('plan-selected', 'internet')">Bestellen</button>
			</div>
		</div>

		<h3 style="margin-bottom: 1em;">Met TV</h3>

		<div class="plan-card">
			<div class="plan-name">
				<h3 class="font-light">All-in-One</h3>
				<h2>Start</h2>
			</div>
			<div class="plan-details">
				<p>
					<em>Alles van Internet Only plus:</em>
				</p>
				<ul>
					<li>25 Nederlandse TV zenders in HD kwaliteit</li>
					<li>Gratis settop box</li>
				</ul>
			</div>
			<div class="plan-sidebox">
				<div class="plan-price">
					<div v-if="productMode == 'helmond-try-buy'" @click="showInfoBox = true">
						<div class="price">1e 3 mnd gratis</div>
						<div class="price-detail">daarna 53,95 p/mnd</div>
					</div>
					<div v-if="productMode === ''">
							<div class="price">53,95</div>
							<div class="price-detail">p/mnd</div>
					</div>
				</div>
				<button class="plan-order" @click="$emit('plan-selected', 'start')">Bestellen</button>
			</div>
		</div>

		<div class="plan-card plan-most-chosen">
			<div class="plan-name">
				<h3 class="font-light">All-in-One</h3>
				<h2>Basis</h2>
				<div class="most-chosen">Meest gekozen</div>
			</div>
			<div class="plan-details">
				<p>
					<em>Alles van Internet Only plus:</em>
				</p>
				<ul>
					<li><strong>80</strong> TV zenders waarvan 50+ in HD kwaliteit</li>
					<li>Inclusief ESPN Compleet (gratis)</li>
					<li>Opnemen, pauzeren, replay, Begin Gemist</li>
					<li>Kijk op (smart) TV, smartphone, tablet en PC</li>
					<li>Gratis settop box</li>
				</ul>
			</div>
			<div class="plan-sidebox">
				<div class="plan-price">
					<div v-if="productMode == 'helmond-try-buy'" @click="showInfoBox = true">
						<div class="price">1e 3 mnd gratis</div>
						<div class="price-detail">daarna 59,95 p/mnd</div>
					</div>
					<div v-if="productMode === ''">
						<div class="price">59,95</div>
						<div class="price-detail">p/mnd</div>
					</div>
				</div>
				<button class="plan-order" @click="$emit('plan-selected', 'basis')">Bestellen</button>
			</div>
		</div>

		<div class="plan-card">
			<div class="plan-name">
				<h3 class="font-light">All-in-One</h3>
				<h2>Extra</h2>
			</div>
			<div class="plan-details">
				<p>
					<em>Alles van Internet Only plus:</em>
				</p>
				<ul>
					<li><strong>115</strong> TV zenders waarvan 50+ in HD kwaliteit</li>
					<li>Inclusief ESPN Compleet (gratis)</li>
					<li>Opnemen, pauzeren, replay, Begin Gemist</li>
					<li>Kijk op (smart) TV, smartphone, tablet en PC</li>
					<li>Gratis settop box</li>
				</ul>
			</div>
			<div class="plan-sidebox">
				<div class="plan-price">
					<div v-if="productMode == 'helmond-try-buy'" @click="showInfoBox = true">
						<div class="price">1e 3 mnd gratis</div>
						<div class="price-detail">daarna 64,95 p/mnd</div>
					</div>
					<div v-if="productMode === ''">
						<div class="price">64,95</div>
						<div class="price-detail">p/mnd</div>
					</div>
				</div>
				<button class="plan-order" @click="$emit('plan-selected', 'extra')">Bestellen</button>
			</div>
		</div>

		<b-button variant="warning" size="lg" @click="$emit('mock-order')">Vul test gegevens in</b-button>
		&nbsp;
		<b-button variant="warning" size="lg" @click="$emit('set-product-mode', 'helmond-try-buy'); showInfoBox = true">Helmond Try & Buy</b-button>

	</div>
</template>

<script>
export default {
	name: 'SelectPlan',
	props: ['isVisible', 'productMode'],
	emits: ['plan-selected', 'mock-order', 'set-product-mode'],
	data() {
		return {
			showInfoBox: false,
		}
	}

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

$fiberBlue: #74d1f6;
$fiberGreen: #91c750;
$fiberOrange: #ef7a30;

.plan-card {
	box-shadow: 0 0 8px 4px rgba(0, 0, 0, .15);
	border-radius: 1em;
	display: grid;
	grid-template-columns: 25% 60% 15%;
	margin-bottom: 2em;
	cursor: pointer;

	.plan-name {
		padding: 1em;
		background-color: $fiberBlue;
		border-top-left-radius: 1em;
		border-bottom-left-radius: 1em;

		h2, h3 {
			color: white;
		}

		h2 {
			font-size: 1.5em;
			margin-bottom: 0em;
		}

		h3 {
			font-size: 1.25em;
			margin-bottom: 0em;
		}
	}

	&.plan-most-chosen .plan-name {
		background-color: $fiberOrange;
	}

	.most-chosen {
		margin-top: 1em;
		display: inline-block;
		background-color: white;
		padding: 0.5em;
		border-radius: 0.5em;
		transform: rotate(-3deg);
		box-shadow: 0 0 12px 4px rgba(0, 0, 0, .15);
		opacity: 0.95;
	}

	.plan-details {
		padding: 1em;

		ul {
			padding-left: 1em;
			margin-bottom: 0 !important;
		}
	}

	.plan-sidebox {
		background-color: #eee;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-bottom-right-radius: 1em;
		border-top-right-radius: 1em;

		.plan-price {
			padding: 1em;
			text-align: center;

			.price {
				font-size: 1.5em;
				font-weight: bold;
			}

			.price-detail {
				font-size: 0.9em;
			}
		}
	}

	.plan-order {
		border-bottom-right-radius: 1em;
	}

}

.plan-order {
	border: none;
	display: block;
	background-color: $fiberGreen;
	color: white;
	font-weight: bold;
	padding: 1em;
	text-decoration: none;
	text-align: center;

	&:hover {
		background-color: #7fba37;
	}
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, .85);
	z-index: 998;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.info-box {
	width: 75%;
	font-size: 1.5em;
	margin-bottom: 1em;
	background-color: #ffffff;
	padding: 2em;
	box-shadow: 0 0 12px 4px rgba(0, 0, 0, .25);
	z-index: 999;
	border-radius: 1em;

	img {
		max-width: 100%;
		max-height: 200px;
		margin-bottom: 2em;
	}

	h1 {
		margin-bottom: 1em;
	}

	ul {
		margin-bottom: 2em;
	}
}

.info-box-top {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.center {
	display: flex;
	justify-content: center;
}

</style>
