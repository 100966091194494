<template>
	<div v-show="isVisible" class="main">

		<h3 class="mb-3">Betaling & akkoord</h3>

		<div class="form-section top">
			<div class="form-section-label">
				Bankgegevens
			</div>
			<div class="form-section-box">
				<p>
					Automatische incasso van bankrekeningnummer:
				</p>
				<b-form-input placeholder="Bankrekeningnummer (IBAN)" class="mb-3" v-model="order.bankAccount.iban" :state="ibanState" :formatter="formatIBAN" />
				<b-form-input placeholder="Naam rekeninghouder" class="mb-3" v-model="order.bankAccount.accountHolder" :state="accountHolderState" />
			</div>
		</div>
		<div class="form-section">
			<div class="form-section-label">
				Akkoord <br />verklaring
			</div>
			<div class="form-section-box">
				<p>
					U gaat akkoord met de volgende voorwaarden:
				</p>

				<div class="form-grid-agreement">
					<b-form-checkbox v-model="order.agreements.general" id="agm_general"></b-form-checkbox>
					<div>
						<label for="agm_general" class="mb-0">
							<h4>Voorwaarden</h4>
							<p>Ik ga akkoord dat er een overeenkomst tot stand komt tussen mij en Fiber Nederland voor de door mij gekozen product(en), tarieven en contractduur, alsmede de product-en actievoorwaarden en de algemene voorwaarden en dat er een check bij een kredietbureau kan worden uitgevoerd.
							</p>
						</label>
					</div>
				</div>

				<div class="form-grid-agreement">
					<b-form-checkbox v-model="order.agreements.withdrawal" id="agm_withdrawal"></b-form-checkbox>
					<div>
						<label for="agm_withdrawal" class="mb-0">
							<h4>Akkoordverklaring machtiging</h4>
							<p>
								Ik ga akkoord met een doorlopende SEPA machtiging. Door het plaatsen van een bestelling geef ik toestemming aan Fiber Nederland (een merk van XS Provider BV) om doorlopend incasso opdrachten te sturen naar het bovengenoemde rekeningnummer om de overeengekomen vergoeding(en) van mijn rekening af te schrijven.
							</p>
						</label>
					</div>
				</div>

				<div class="form-grid-agreement" v-show="order.product.hasTv && order.product.tv.provider === 'canal_digitaal'">
					<b-form-checkbox v-model="order.agreements.canalDigitaal" id="agm_tv"></b-form-checkbox>
					<div>
						<label for="agm_tv" class="mb-0">
							<h4>Additionele akkoordverklaring televisie</h4>
							<p>
								Ik ga akkoord met de algemene voorwaarden van Canal Digitaal, een merk van Canal+ Luxembourg S.à.r.l. voor de levering van de TV diensten.
							</p>
						</label>
					</div>
				</div>

				<div class="form-grid-agreement" v-show="productMode === 'helmond-try-buy'">
					<b-form-checkbox v-model="order.agreements.helmond_try_buy" id="try-buy"></b-form-checkbox>
					<div>
						<label for="try-buy" class="mb-0">
							<h4>Additionele akkoordverklaring actievoorwaarden</h4>
							<p>
								Ik ga akkoord met de volgende actievoorwaarden. De periode van 3 maanden gratis gebruik gaat in op dag dat de diensten zijn opgeleverd bij de installatie van onze monteur.
								Indien u geen gebruik wenst te blijven maken van de diensten na deze periode, dan kunt u deze overeenkomst kosteloos beeïndigen. Hiervoor dient u zelf tijdig op te zeggen, binnen deze periode van 3 maanden.
								Indien u niet of niet tijdig opzegt, loopt de overeenkomst door voor de gekozen duur van 1 of 2 jaar.
							</p>
						</label>
					</div>
				</div>

			</div>
		</div>
		<div class="mt-3" style="display: flex; justify-content: center;">
			<b-button :disabled="formState === false" @click="$emit('agreement-done')" class="btn-green">Akkoord</b-button>
		</div>

	</div>
</template>

<script>
export default {
	name: 'Agreement',
	props: ['isVisible', 'order', 'productMode'],
	emits: ['agreement-state'],
	data() {
		return {
		}
	},
	computed: {
		ibanState()
		{
			let ret = null;
			if (this.order.bankAccount.iban)
			{
				let regex = new RegExp(/^[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{10}$/);
				ret = regex.test(this.order.bankAccount.iban);

				if (ret)
				{
					ret = this.$iban.isValidIBAN(this.order.bankAccount.iban);
				}
			}
			return ret;
		},
		accountHolderState()
		{
			let ret = null;
			if (this.order.bankAccount.accountHolder)
			{
				ret = /^[A-Za-z\-' .]{5,}$/i.test(this.order.bankAccount.accountHolder);
			}
			return ret;
		},

		formState()
		{
			let ret = true;

			// Check iban and account holder
			if (!this.ibanState || !this.accountHolderState)
			{
				ret = false;
			}

			// Check conditions
			if (!this.order.agreements.general || !this.order.agreements.withdrawal)
			{
				ret = false;
			}

			// Check CD conditions if needed
			if (this.order.product.tv.provider === 'canal_digitaal')
			{
				if (!this.order.agreements.canalDigitaal)
				{
					ret = false;
				}
			}

			this.$emit('agreement-state', ret);

			return ret;
		},

	},
	methods: {
		formatIBAN(val)
		{
			let cleanValue = val.replace(/[^A-Z0-9]/gi, '');
			cleanValue = cleanValue.toUpperCase();
			cleanValue = cleanValue.substring(0, 18);
			return cleanValue;
		},

	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

$fiberBlue: #74d1f6;
$fiberGreen: #91c750;
$fiberOrange: #ef7a30;

h3 {
	font-size: 1.25em;
}

h4 {
	font-size: 1em;
	margin-bottom: 0;
}

.form-grid-agreement {
	display: grid;
	grid-template-columns: 5% 1fr;
	grid-column-gap: 1em
}

</style>
