<template>
	<div v-show="isVisible" class="main">

		<div class="form-section top mb-3">
			<div class="form-section-label">
				Uw gegevens
			</div>
			<div class="form-section-box">
				<div class="form-grid-2">
					<div>
						{{  order.person.title }} {{order.person.initials }} {{ fullName }}<br />
						{{  order.address.street }} {{ order.address.houseNumber }} {{ order.address.houseNumberSuffix }} <br />
						{{ order.address.postalCode }} {{  order.address.city }} <br />
						<div class="email-box"><span>{{  order.person.email }}</span></div>
					</div>
					<div>
						{{  order.person.dateOfBirth.date }} <br />
						{{  order.person.phone }} <br />
						{{  order.person.mobile }}
					</div>
				</div>
			</div>
		</div>

		<h4>Uw abonnement</h4>

		<div class="form-section top">
			<div class="form-section-label">
				Pakket
			</div>
			<div class="form-section-box">
				<div class="form-line-3-col-price" v-if="productMode === ''">
					<div>
						<strong>{{ order.product.plan.name }}</strong>
					</div>
					<div>
						<strong>{{ contractTerm }}</strong>
					</div>
					<div class="price">
						<strong>€ {{ order.product.plan.price | formatNumber }}</strong>
					</div>
				</div>
				<div class="form-line-3-col-price" v-if="productMode === 'helmond-try-buy'">
					<div>
						<strong>{{ order.product.plan.name }}</strong>
						<span class="highlight">&nbsp;(eerste 3 maanden)</span>
					</div>
					<div>
						<strong>{{ contractTerm }}</strong>
					</div>
					<div class="price">
						<span v-if="productMode === 'helmond-try-buy'" class="campaign-badge">
							ACTIE
						</span>
						<span v-if="productMode === 'helmond-try-buy'" class="highlight">
							€ 0,00
						</span>
					</div>
				</div>
				<div class="form-line-3-col-price" v-if="productMode === 'helmond-try-buy'">
					<div>
						na 3 maanden
					</div>
					<div>
					</div>
					<div class="price">
						<strong>€ {{ order.product.plan.price | formatNumber }}</strong>
					</div>
				</div>
			</div>
		</div>
		<div class="form-section" v-show="order.product.hasTv">
			<div class="form-section-label">
				Televisie
			</div>
			<div class="form-section-box">
				<div class="form-line-2-col-price" v-show="order.product.tv.provider == 'canal_digitaal'">
					<div>
						{{ order.product.tv.package }} van Canal Digitaal
					</div>
					<div class="price">
						Inbegrepen
					</div>
				</div>
				<div class="form-line-2-col-price">
					<div>
						Inclusief 1 settop box
					</div>
					<div class="price">
						Inbegrepen
					</div>
				</div>
				<div class="form-line-2-col-price" v-show="order.product.tv.extraSettopBoxes > 0">
					<div>
						{{ order.product.tv.extraSettopBoxes }} extra settop boxen ({{ order.product.tv.extraSettopBoxes }} x € 5,00)
					</div>
					<div class="price">
						€ {{ extraSettopBoxesFee | formatNumber }}
					</div>
				</div>
				<div class="form-line-2-col-price" v-show="order.product.tv.extraPackages.ziggo">
					<div>
						Ziggo Sport Totaal
					</div>
					<div class="price">
						€ 13,95
					</div>
				</div>
				<div class="form-line-2-col-price" v-show="order.product.tv.extraPackages.film1">
					<div>
						Film1
					</div>
					<div class="price">
						€ 15,00
					</div>
				</div>
				<div class="form-line-2-col-price" v-show="order.product.tv.extraPackages.entmt">
					<div>
						Entertainment HD
					</div>
					<div class="price">
						€ 5,00
					</div>
				</div>
			</div>
		</div>
		<div class="form-section" v-show="order.product.hasPhone">
			<div class="form-section-label">
				Telefonie
			</div>
			<div class="form-section-box">
				<div class="form-line-2-col-price">
					<div>
						Gratis vaste telefoonlijn
						<span v-show="order.product.phone.firstNumber.migrate">met nummerbehoud {{ order.product.phone.firstNumber.numberToMigrate }}</span>
						<span v-show="!order.product.phone.firstNumber.migrate">met nieuw nummer</span>
					</div>
					<div class="price">
						€ 0,00
					</div>
				</div>
				<div class="form-line-2-col-price" v-show="order.product.phone.package.code !== '' && order.product.phone.package.code !== null">
					<div>
						{{ order.product.phone.package.name }}
					</div>
					<div class="price">
						€ {{ order.product.phone.package.price | formatNumber }}
					</div>
				</div>
				<div class="form-line-2-col-price" v-show="order.product.phone.hasSecondNumber">
					<div>
						Extra vaste telefoonlijn
						<span v-show="order.product.phone.secondNumber.migrate">met nummerbehoud {{ order.product.phone.secondNumber.numberToMigrate }}</span>
						<span v-show="!order.product.phone.secondNumber.migrate">(nieuw nummer)</span>
					</div>
					<div class="price">
						€ 4,95
					</div>
				</div>
			</div>
		</div>

		<div class="form-section-total" v-if="productMode === ''">
			<div class="total-label">
				Totaalbedrag maandelijks
			</div>
			<div class="price-total">
				€ {{ order.product.totalRecurring | formatNumber }}
			</div>
		</div>
		<div class="form-section-total" v-if="productMode === 'helmond-try-buy'" style="padding-bottom: 0em;">
			<div class="total-label">
				Totaalbedrag maandelijks (eerste 3 maanden)
			</div>
			<div class="price-total">
				€ 0,00
			</div>
		</div>
		<div class="form-section-total" v-if="productMode === 'helmond-try-buy'">
			<div class="total-label" style="font-weight: normal; font-size: 1rem;">
				Totaalbedrag maandelijks (na 3 maanden)
			</div>
			<div class="price-total" style="font-weight: normal; font-size: 1rem;">
				€ {{ order.product.totalRecurring | formatNumber }}
			</div>
		</div>

		<div class="form-section top" v-show="order.product.totalOneOff > 0">
			<div class="form-section-label">
				Eenmalige kosten
			</div>
			<div class="form-section-box">
				<div class="form-line-2-col-price">
					<div>
						Activatiekosten internet
					</div>
					<div class="price">
						<div v-if="productMode === ''">
							€ {{ order.product.activationCostInternet | formatNumber }}
						</div>
						<span v-if="productMode === 'helmond-try-buy'" class="campaign-badge">
							ACTIE
						</span>
						<span v-if="productMode === 'helmond-try-buy'" class="highlight">
							€ 0,00
						</span>
					</div>
				</div>
				<div class="form-line-2-col-price" v-show="order.product.hasTv">
					<div>
						Activatiekosten televisie
					</div>
					<div class="price">
						<div v-if="productMode === ''">
						€ 25,00
						</div>
						<span v-if="productMode === 'helmond-try-buy'" class="campaign-badge">
							ACTIE
						</span>
						<span v-if="productMode === 'helmond-try-buy'" class="highlight">
							€ 0,00
						</span>
					</div>
				</div>
				<div class="form-line-2-col-price" v-show="order.product.phone.firstNumber.migrate">
					<div>
						Nummerbehoud 1e telefoonnummer
					</div>
					<div class="price">
						€ 0,00
					</div>
				</div>
				<div class="form-line-2-col-price" v-show="order.product.phone.secondNumber.migrate">
					<div>
						Nummerbehoud 2e telefoonnummer
					</div>
					<div class="price">
						€ 9,95
					</div>
				</div>
			</div>
		</div>
		<div class="form-section-total">
			<div class="total-label">
				Totaalbedrag eenmalig
			</div>
			<div class="price-total">
				€ {{ order.product.totalOneOff | formatNumber }}
			</div>
		</div>

		<div class="form-section top">
			<div class="form-section-label">
				Borg
			</div>
			<div class="form-section-box">
				<div class="form-line-2-col-price">
					<div>
						Fritz!Box wifi router (gratis in bruikleen)
					</div>
					<div class="price">
						€ 49,95
					</div>
				</div>
			</div>
		</div>
		<div class="form-section top">
			<div class="form-section-label">
				Levering
			</div>
			<div class="form-section-box">
				<div class="form-line-2-col-price">
					<div>
						<span v-show="order.product.delivery === 'asap'">
							Zo spoedig mogelijk
						</span>
						<span v-show="order.product.delivery === 'wishdate'">
							Wensdatum: {{ order.product.wishDate | formatDate }}
						</span>
					</div>
					<div class="price">

					</div>
				</div>
			</div>
		</div>
		<div class="form-section top">
			<div class="form-section-label">
				Installatie
			</div>
			<div class="form-section-box">
				<div class="form-line-2-col-price">
					<div>
						Gratis basis installatie door monteur
					</div>
					<div class="price">
						€ 0,00
					</div>
				</div>
			</div>
		</div>

		<div class="mt-3" style="display: flex; justify-content: flex-end;">
			<b-button @click="$emit('overview-done')" class="btn-green">Verder</b-button>
		</div>

	</div>
</template>

<script>
export default {
	name: 'Overview',
	props: ['isVisible', 'order', 'productMode'],
	emits: [],
	data() {
		return {
		}
	},
	computed: {
		fullName()
		{
			let name = this.order.person.middleName + ' ' + this.order.person.lastName;
			return name.trim();
		},
		contractTerm()
		{
			return (this.order.product.contractTerm === 1 ? '1 jarig contract' : '2 jarig contract');
		},
		extraSettopBoxesFee()
		{
			return (this.order.product.tv.extraSettopBoxes * 5.00);
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

$fiberBlue: #74d1f6;
$fiberGreen: #91c750;
$fiberOrange: #ef7a30;

.form-line-3-col-price {
	display: grid;
	grid-template-columns: 3fr 2fr 1fr;
}

.form-line-2-col-price {
	display: grid;
	grid-template-columns: 5fr 1fr;
}

.price {
	text-align: right;
}

.price-total {
	text-align: right;
	font-weight: bold;
	font-size: 1.1em;
}

.form-section-total {
	font-size: 1.1em;
	font-weight: bold;
	background-color: #fff !important;
}

.email-box {
	background-color: #ffffcc;
	padding: 0.25em 1em;
	margin-left: -1rem;
	font-size: 1.25em;
}

.highlight {
	background-color: #ffffc0;
}

.campaign-badge {
	background-color: #76ba24;
	color: white;
	padding: 0em 0.75em;
	margin-right: 1em;
	border-radius: 1em;
}

</style>
