<template>
	<div id="app">

		<div class="content">

			<Navbar
				:active-step="activeStep"
				:completed-steps="completedSteps"
				@select-step="onStepClick"
				@reset-form="onResetForm"
				v-show="activeStep !== 6"
			/>

			<div>

				<SelectPlan
					@plan-selected="onPlanSelected"
					@mock-order="onMockOrder"
					@set-product-mode="onSetProductMode"
					:product-mode="productMode"
					v-show="activeStep === 1"
				/>

				<SelectOptions
					@options-done="onOptionsDone"
					@price-affected="onPriceAffected"
					@options-form-state="onOptionsFormStateChange"
					v-show="activeStep === 2"
					:order="order"
					:product-mode="productMode"
				/>

				<CustomerData
					@customer-data-done="onCustomerDataDone"
					@customer-form-state="onCustomerDataStateChange"
					:order="order"
					v-show="activeStep === 3"
				/>

				<Overview
					@overview-done="onOverviewDone"
					v-show="activeStep === 4"
					:order="order"
					:product-mode="productMode"
				/>

				<Agreement
					@agreement-done="onAgreementDone"
					v-show="activeStep === 5"
					:order="order"
					:product-mode="productMode"
				/>

				<Thanks
					@reset="onReset"
					:order="order"
					v-show="activeStep === 6"
				/>

				<b-modal id="reset-form-modal" hide-footer>
					<template #modal-title>
						Reset formulier
					</template>
					<div class="d-block text-center">
						Alle gegevens worden gewist. Weet je het zeker?
					</div>
				</b-modal>

			</div>

		</div>

		<footer>
			Version: {{ appVersion }}
		</footer>

	</div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import SelectPlan from "./components/SelectPlan.vue";
import SelectOptions from "./components/SelectOptions.vue";
import CustomerData from "./components/CustomerData.vue";
import Overview from "./components/Overview.vue";
import Agreement from "./components/Agreement.vue";
import Thanks from "./components/Thanks.vue";

export default {
	created() {
		this.resetOrderData();
	},
	components: {Thanks, Agreement, Overview, CustomerData, SelectOptions, Navbar, SelectPlan},
	data()
	{
		return {
			appVersion: '1.2.0',
			activeStep: 1,
			completedSteps: {
				plan: false,
				options: false,
				customerData: false,
				overview: false,
				agreement: false,
			},
			// Switch to support specific campaigns ('try-buy-helmond')
			productMode: '',
			order: null,
		};
	},
	methods: {
		calculateTotalRecurring()
		{
			// Start with plan price
			let total = this.order.product.plan.price;

			// Add extra settop boxes
			total += (this.order.product.tv.extraSettopBoxes * 5.00);

			// Add extra TV packages
			if (this.order.product.tv.extraPackages.ziggo)
			{
				total += 13.95;
			}
			if (this.order.product.tv.extraPackages.film1)
			{
				total += 15;
			}
			if (this.order.product.tv.extraPackages.entmt)
			{
				total += 5;
			}

			// Phone package
			if (this.order.product.phone.package.code === 'onbeperkt_vast_nl')
			{
				total += 9.95;
			}
			if (this.order.product.phone.package.code === 'onbeperkt_vast_mobiel_nl')
			{
				total += 14.95;
			}
			if (this.order.product.phone.package.code === 'onbeperkt_nl_buitenland_vast')
			{
				total += 24.95;
			}

			// Add second number
			if (this.order.product.phone.hasSecondNumber)
			{
				total += 4.95;
			}

			return total;
		},

		calculateTotalOneOff()
		{
			let total = 0;

			this.order.product.contractTerm = parseInt(this.order.product.contractTerm);

			// Activation internet (for 1 year contract)
			this.order.product.activationCostInternet = 0;
			if (this.order.product.contractTerm === 1)
			{
				this.order.product.activationCostInternet = 24.95;
				total += this.order.product.activationCostInternet;
			}

			if (this.order.product.hasTv)
			{
				total += 25.00;
			}

			// No activation costs for try & buy
			if (this.productMode === 'helmond-try-buy')
			{
				total = 0;
			}

			// Add migration of second number
			if (this.order.product.phone.secondNumber.migrate)
			{
				total += 9.95;
			}

			return total;
		},

		isActiveStep(step)
		{
			return (this.activeStep === step);
		},

		onPlanSelected(plan)
		{
			this.order.product.plan.code = plan;

			if (this.order.product.hasPhone === null)
			{
				this.order.product.hasPhone = false;
			}

			switch (plan)
			{
				case 'internet':
					this.order.product.plan.name = 'Internet Only';
					this.order.product.plan.price = 44.95;
					this.order.product.hasTv = false;
					this.order.product.tv.extraSettopBoxes = 0;
					this.order.product.tv.extraPackages.ziggo = false;
					this.order.product.tv.extraPackages.film1 = false;
					this.order.product.tv.extraPackages.entmt = false;
					break;
				case 'start':
					this.order.product.plan.name = 'All-in-One Start';
					this.order.product.plan.price = 53.95;
					this.order.product.hasTv = true;
					this.order.product.tv.provider = 'bright_blue';
					this.order.product.tv.canHaveExtraPackages = false;
					break;
				case 'basis':
					this.order.product.plan.name = 'All-in-One Basis';
					this.order.product.plan.price = 59.95;
					this.order.product.hasTv = true;
					this.order.product.tv.provider = 'canal_digitaal';
					this.order.product.tv.package = 'Basis HD';
					this.order.product.tv.canHaveExtraPackages = true;
					break;
				case 'extra':
					this.order.product.plan.name = 'All-in-One Extra';
					this.order.product.plan.price = 64.95;
					this.order.product.hasTv = true;
					this.order.product.tv.provider = 'canal_digitaal';
					this.order.product.tv.package = 'Entertainment HD';
					this.order.product.tv.canHaveExtraPackages = true;
					this.order.product.tv.extraPackages.entmt = false;
					break;
			}

			this.order.product.totalRecurring = this.calculateTotalRecurring();
			this.order.product.totalOneOff = this.calculateTotalOneOff();

			this.activeStep = 2;
			this.completedSteps.plan = true;
		},

		onPriceAffected()
		{
			this.order.product.totalRecurring = this.calculateTotalRecurring();
			this.order.product.totalOneOff = this.calculateTotalOneOff();
		},

		onOptionsFormStateChange(state)
		{
			this.completedSteps.options = state;
		},

		onCustomerDataStateChange(state)
		{
			this.completedSteps.customerData = state;
		},

		onStepClick(step)
		{
			// If navigating backwards
			if (step < this.activeStep)
			{
				if (this.activeStep === 5)
				{
					this.completedSteps.agreement = false;
				}
				if (this.activeStep === 4)
				{
					this.completedSteps.overview = false;
				}
				if (this.activeStep === 3)
				{
					this.completedSteps.customerData = false;
				}
				if (this.activeStep === 2)
				{
					this.completedSteps.options = false;
				}
			}
			this.activeStep = step;
		},

		onOptionsDone()
		{
			this.activeStep = 3;
			this.completedSteps.options = true;
		},

		onCustomerDataDone()
		{
			this.activeStep = 4;
			this.completedSteps.customerData = true;
		},

		onOverviewDone()
		{
			this.activeStep = 5;
			this.completedSteps.overview = true;
		},

		onAgreementDone()
		{
			// Process order
			this.submitOrder();
		},

		onReset()
		{
			this.resetOrderData();
			this.activeStep = 1;
			this.completedSteps.plan = false;
			this.completedSteps.options = false;
			this.completedSteps.customerData = false;
			this.completedSteps.overview = false;
			this.completedSteps.agreement = false;
		},

		onResetForm()
		{
			this.$bvModal.msgBoxConfirm('Weet je zeker dat je het formulier wilt wissen?', {
				title: 'Formulier reset',
				size: 'lg',
				buttonSize: 'lg',
				okVariant: 'danger',
				okTitle: 'JA',
				cancelTitle: 'Nee',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			})
			.then(value => {
				if (value)
				{
					this.onReset();
				}
			})
			.catch(err => {
				// An error occurred
			});
		},

		resetOrderData()
		{
			this.checkAppVersion();

			this.order = {
				product: {
					plan: {
						code: null,
						name: null,
						price: null,
					},
					hasTv: null,
					hasPhone: null,
					tv: {
						provider: null,
						package: null,
						extraSettopBoxes: 0,
						canHaveExtraPackages: null,
						extraPackages: {
							ziggo: false,
							film1: false,
							entmt: false,
						},
					},
					phone: {
						firstNumber: {
							migrate: null,
							numberToMigrate: null,
						},
						hasSecondNumber: false,
						secondNumber: {
							migrate: null,
							numberToMigrate: null,
						},
						package: {
							code: '',
							name: null,
							price: 0,
						},
					},
					contractTerm: 1,
					totalRecurring: null,
					totalOneOff: null,
					delivery: null,
					wishDate: null,
				},
				person: {
					gender: null,
					title: null,
					initials: null,
					firstName: null,
					middleName: null,
					lastName: null,
					dateOfBirth: {
						day: null,
						month: null,
						year: null,
						date: null,
					},
					phone: null,
					mobile: null,
					email: null,
				},
				address: {
					street: null,
					houseNumber: null,
					houseNumberSuffix: null,
					roomNumber: null,
					postalCode: null,
					city: null,
				},
				bankAccount: {
					iban: null,
					accountHolder: null,
				},
				agreements: {
					general: false,
					withdrawal: false,
					canalDigitaal: false,
				},
				location: {
					lat: 0,
					lng: 0,
				}
			};

			this.setLocation();
		},

		onMockOrder()
		{
			this.order = {
				product: {
					plan: {
						code: 'basis',
						name: 'All-in-One Basis',
						price: 59.95,
					},
					hasTv: true,
					hasPhone: true,
					tv: {
						provider: 'canal_digitaal',
						package: 'Basis HD',
						extraSettopBoxes: 4,
						canHaveExtraPackages: true,
						extraPackages: {
							ziggo: true,
							film1: true,
							entmt: true,
						},
					},
					phone: {
						firstNumber: {
							migrate: true,
							numberToMigrate: '0201234567',
						},
						hasSecondNumber: true,
						secondNumber: {
							migrate: true,
							numberToMigrate: '0301234567',
						},
						package: {
							code: 'onbeperkt_nl_buitenland_vast',
							name: 'Onbeperkt vast en mobiel NL + buitenland vast',
							price: 24.95,
						}
					},
					contractTerm: 2,
					totalRecurring: 999.99,
					totalOneOff: 99.99,
					delivery: 'wishdate',
					wishDate: '2022-01-01',
					activationCostInternet: 0.00,
				},
				person: {
					gender: 'F',
					title: 'Mevr',
					initials: 'T.E.S.T.',
					firstName: 'Tester',
					middleName: 'van der',
					lastName: 'Test',
					dateOfBirth: {
						day: 10,
						month: 10,
						year: '1970',
						date: null,
					},
					phone: '0201234567',
					mobile: '0612345678',
					email: 'tester@test.com',
				},
				address: {
					street: 'Teststraat',
					houseNumber: '123',
					houseNumberSuffix: 'A',
					roomNumber: '2',
					postalCode: '1234AB',
					city: 'Testerdam',
				},
				bankAccount: {
					iban: 'NL79INGB4370891833',
					accountHolder: 'T. van der Test',
				},
				agreements: {
					general: true,
					withdrawal: true,
					canalDigitaal: true,
				},
				location: {
					lat: 0,
					lng: 0,
				}
			};
			this.order.product.totalRecurring = this.calculateTotalRecurring();
			this.order.product.totalOneOff = this.calculateTotalOneOff();
			this.completedSteps.plan = true;
			this.completedSteps.options = true;
			this.completedSteps.customerData = true;
			this.completedSteps.overview = true;
			this.completedSteps.agreement = true;

			if (this.productMode === 'helmond-try-buy')
			{
				this.order.agreements.helmond_try_buy = true;
			}

			this.setLocation();
		},

		onSetProductMode(mode)
		{
			this.productMode = mode;

			if (this.productMode === 'helmond-try-buy')
			{
				this.order.agreements.helmond_try_buy = false;
			}
		},

		submitOrder()
		{
			let apiLocation = '';
			switch (location.hostname)
			{
				case 'fuzhou':
					apiLocation = 'api.aika.local.dev';
					break;
				case 'd2d.test.fiber.nl':
					apiLocation = 'api.aika.test.fiber.nl';
					break;
				case 'd2d.fiber.nl':
					apiLocation = 'api.aika.fiber.nl';
					break;
			}

			let url = 'https://' + apiLocation + '/api/orders';
			console.log('Sending order to ' + url);

			let config = {
				headers: {
					'X-D2D-App-Version': this.appVersion,
				}
			}

			// Add discount for Helmond try & buy
			if (this.productMode === 'helmond-try-buy')
			{
				this.order.product.plan.initialPrice = 0;
				this.order.product.plan.standardPrice = this.order.product.plan.price;
				delete this.order.product.plan.price;

				this.order.product.discount = {
					type: 'period',
					amount: null,
					months: 3,
				};

				// Indicate no activation cost
				this.order.product.noActivationCost = true;
				if (this.order.product.hasTv)
				{
					this.order.product.tv.noActivationCost = true;
				}

				// Set conditions for order
				this.order.conditions = ["xsp_conditions","xsp_withdrawal"];
				if (this.order.product.hasTv && this.order.product.tv.provider === 'canal_digitaal')
				{
					this.order.conditions.push('canal_digitaal');
				}
				this.order.conditions.push('helmond_try_buy');
			}

			this.$http.post(url, this.order, config)
				.then(resp =>
				{
					this.activeStep = 6;
					this.completedSteps.agreement = true;
				})
				.catch(error =>
				{
					//alert();
					console.log(error.response);

					if (error.response.data.result === 'error')
					{
						alert(error.response.data.details);
					}
				});
		},

		setLocation()
		{
			navigator.geolocation.getCurrentPosition(
				position => {
					this.order.location.lat = position.coords.latitude;
					this.order.location.lng = position.coords.longitude;
				},
				error => {
					this.order.location.lat = 999;
					this.order.location.lng = 999;
					console.log(error.message);
				},
			)
		},

		checkAppVersion()
		{
			let apiLocation = '';
			switch (location.hostname)
			{
				case 'fuzhou':
					apiLocation = 'api.aika.local.dev';
					break;
				case 'd2d.test.fiber.nl':
					apiLocation = 'api.aika.test.fiber.nl';
					break;
				case 'd2d.fiber.nl':
					apiLocation = 'api.aika.fiber.nl';
					break;
			}

			let url = 'https://' + apiLocation + '/api/d2d-version';
			console.log('Checking D2D app version at ' + url);

			this.$http.get(url)
				.then(resp =>
				{
					if (resp.data.version)
					{
						if (resp.data.version !== this.appVersion)
						{
							alert('Deze versie van de applicatie (' + this.appVersion + ') wordt niet meer ondersteund.\nDe huidige versie is ' + resp.data.version + '.\n\nHerlaadt de applicatie svp.');
						}
						else
						{
							console.log('App version is OK (' + this.appVersion + ')');
						}
					}
					else
					{
						alert('Versie van de applicatie kan niet gecontroleerd worden. Neem contact op met ons op.');
					}
				})
				.catch(error =>
				{
					console.log(error.response);
				});
		}
	},
}
</script>

<style lang="scss">

$fiberGreen: #91c750;
$fiberBlue: #74d1f6;

html, body, #app {
	height: 100%;
}

#app {
	display: flex;
	flex-direction: column;
}

body, h1, h2, h3, h4 {
	font-family: "Nunito Sans", Verdana, Arial, sans-serif;
	color: #444;
}

.content {
	flex: 1 0 auto;
}

footer {
	flex-shrink: 0;
}

h1, h2, h3, h4 {
	font-weight: 700;
}

h4 {
	font-size: 1.15em;
}

.font-light {
	font-weight: 300;
}

.main {
	padding: 30px;
}

.form-section {
	display: grid;
	grid-template-columns: 20% 1fr;
	border-bottom: 1px solid #ddd;

	.form-section-label {
		padding: 0.5em 1em;
		background-color: #f6f6f6;
		background: linear-gradient(135deg, rgba(248,248,248,1) 0%, rgba(240,240,240,1) 100%);
		font-weight: bold;
	}

	.form-section-box {
		padding: 0.5em 1em;
	}

	.form-line-heading {
		margin-bottom: 0.5em;
	}
}

.form-section.top {
	border-top: 1px solid #ddd;
}

.form-section-total {
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding: 0.5em 1em;
}

.form-line-2-col {
	display: grid;
	grid-template-columns: 80% 20%;
}

.form-line-2-col-50-50 {
	display: grid;
	grid-template-columns: 50% 1fr;
	grid-column-gap: 2em;
}

.line-price, .multiple-checkbox {
	text-align: right;
}

.divider {
	height: 1px;
	border-top: 1px solid #ddd;
	margin-bottom: 0.5em;
}

.multiple-checkbox label {
	margin-left: 1em;
	margin-bottom: 0;
}

.light-btn {
	background-color: #999;
	border: none;
}

.show-phone-toggle {
	margin-top: 0.5em;
}

.form-group {
	margin-bottom: 0.5em;
}

.btn-green {
	background-color: $fiberGreen;
	border: none;
	font-size: 1.5em;
	min-width: 150px;
}

.form-grid-2 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 1em
}

.form-grid-3 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 1em
}

.form-grid-1-3 {
	display: grid;
	grid-template-columns: 1fr 3fr;
	grid-column-gap: 1em
}

.custom-control-inline {
	margin-right: 0.5rem !important;
}

.form-error {
	background-color: #ffefef;
	color: #bb0000;
	padding: 0.5em 1em;
}

.btn-secondary:hover {
	background-color: #76ba24;
}

.btn-secondary:disabled {
	background-color: #ccc;
}

footer {
	background-color: #333;
	color: #ccc;
	padding: 0.5em;
	text-align: center;
	font-size: 13px;
}

</style>
