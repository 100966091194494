<template>
	<div v-show="isVisible" class="fullscreen">

		<div class="topbar">
			<div>
				<img class="mt-4 mb-4" src="/images/fiber.png" alt="Fiber NL" />
			</div>
		</div>

		<div class="top-banner">
		</div>

		<div class="card">
			<h2>Gefeliciteerd, welkom bij Fiber!</h2>

			<p>
				U ontvangt een bevestiging van uw aanmelding op {{ order.person.email }}.
			</p>

			<h3>Heeft u later nog vragen?</h3>
			<p> Mail naar info@fiber.nl <br /> of bel ons: 020 760 50 40.</p>
		</div>

		<div class="mt-5" style="display: flex; justify-content: center;">
			<b-button @click="$emit('reset')" class="btn-light" style="padding: 0.5em 2em;">Volgende klant</b-button>
		</div>

	</div>
</template>

<script>
export default {
	name: 'Thanks',
	props: ['isVisible', 'order'],
	emits: [],
	data() {
		return {
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

$fiberBlue: #74d1f6;
$fiberGreen: #91c750;
$fiberOrange: #ef7a30;

.topbar {
	background-color: #fff !important;
	display: flex;
	justify-content: center;
}

.fullscreen {
	height: 100vh;
	background-color: #eee;
}

.top-banner {
	background-image: url('/images/happy_family.jpg');
	background-size: cover;
	height: 500px;
}

.card {
	width: 75%;
	margin-top: -5em;
	margin-left: auto;
	margin-right: auto;
	padding: 2em;
	border-top-left-radius: 2em;
	border-bottom-right-radius: 2em;
	box-shadow: 0 0 12px 4px rgba(0,0,0, .15);
}

h2 {
	color: $fiberBlue;
	font-size: 2em;
	margin-bottom: 0.75em;
}

p {
	font-size: 1.5em;
}

h3 {
	font-size: 1.5em;
}

</style>
